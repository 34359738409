@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h2 {
    @apply text-center text-3xl md:text-5xl text-slate-500 uppercase font-fingerPaint ;
  }
}

@layer components {
  .btn {
    @apply py-1 md:py-3 md:px-5 px-6  border-2 border-[#4B6CC1] bg-[#4B6CC1]  text-white  rounded-full opacity-90 transition ease-in-out duration-500 hover:bg-white dark:hover:bg-transparent hover:text-[#4B6CC1]  ;
  }
  .blinking-cursor::after {
    content: "|";
    @apply text-blue-500;
    animation: blink 1s step-start infinite;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
}
